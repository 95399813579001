.root {
  --background-min-height: min(100vh, 600px);
  --offset-top: calc(var(--space-m) + var(--nav-height) + var(--banner-height));

  position: relative;
  margin: var(--offset-top) var(--layout-gutter) 0;
  overflow: hidden;
}

.info {
  display: flex;
  position: relative;
  flex-direction: column;
  min-height: var(--background-min-height);
  padding: var(--space-m-l);

  & h1 {
    color: var(--color-text-on-color);
  }
}

.background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-default);
  background-color: var(--color-background-inverted);
  background-image: linear-gradient(
    to right,
    var(--color-background-inverted) 30%,
    var(--color-accent-default) 350%
  );
}

.background > * {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.errorCode {
  display: block;
  opacity: 0.5;
}

.text {
  max-width: 40ch;
  margin-top: var(--space-m);
  margin-bottom: var(--space-l);
  color: var(--color-text-default);
}

@media (--medium) {
  .root {
    --background-min-height: min(90vh, 2000px);
  }

  .info {
    padding: var(--space-l-xl);
    aspect-ratio: 16 / 9;
  }
}
