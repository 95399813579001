.root.hasAnimation {
  display: none;
}

@supports (offset-path: path('M -600 300 L 1800 300')) {
  .root.hasAnimation {
    display: block;
  }
}

[data-inview] .root.hasAnimation {
  animation: pathAnimation var(--animation-duration) ease;
  animation-delay: var(--animation-delay);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

[data-inview] .root.infiniteAnimation {
  animation-iteration-count: infinite;
}

@keyframes pathAnimation {
  0% {
    offset-distance: 0;
  }

  100% {
    offset-distance: 100%;
  }
}
